import { extendTheme, theme } from '@chakra-ui/react';
import '@fontsource/work-sans';

export const BRAND = {
  red: '#E71E25',
  blue: '#00A8D0',
  darkBlue: '#0097bd',
  white: '#D2DADF',
};

export const PAGESIZE = { w: ['100vw'], minH: ['100vh'] };

export const WIDTH = {
  maxWidth: '1600px',
};

export const PAGE_FLEX = {
  w: ['95vw', 'auto'],
  ml: ['none', '100px'],
  mx: ['auto', 'none'],
  pos: 'relative',
  flexDir: 'column',
};

export const HEADING_PROPS = {
  as: 'h2',
  textAlign: 'left',
  fontSize: ['23px', '30px'],
  zIndex: 1,
  color: '#D2DADF',
  w: ['auto', '700px'],
  h: '50px',
  mt: ['110px', '110px'],
  mb: '8',
  pos: 'relative',
  lineHeight: 1.5,
  fontWeight: '200',
  mx: ['auto', 0],
};

export const BGCOLOR = {
  bg: 'blue.500',
  iconBg: 'blue.600',
  docBg: 'rgba(235, 248, 255, 0.5)',
  docBgDark: 'rgba(230, 243, 255, 1)',
  navbarColor: '#060E28',
};
export const MAINFONT = {
  color: 'blue.600',
};
export const FONTSIZE = {
  heading: ['3xl', '5xl'],
  h2: ['2xl', '40px'],
  h3: ['20px', '20px'],
};

export const SHADOW = {
  feedhive: '0 1.5px 10px rgba(0,0,0,0.06),0 10px 80px rgba(0,0,0,0.12)',
};

const colors = {
  ...theme,
  fonts: {
    ...theme.fonts,
    heading: 'Work Sans',
    body: 'Work Sans',
  },
  colors: {
    ...theme.colors,
  },
};
export default extendTheme(colors);
